<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        :persistent="isLoading != null"
        max-width="500"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('dialogs.files.createFolder.title') }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="valid"
                @submit.prevent="createFolder">
              <v-row>
                <v-text-field
                    ref="inputField"
                    :label="$t('dialogs.files.createFolder.fields.entryName.label')"
                    :hint="$t('dialogs.files.createFolder.fields.entryName.hint')"
                    :disabled="isLoading != null"
                    :rules="entryNameRules"
                    v-model="entryName"></v-text-field>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="secondary"
              text
              :disabled="isLoading != null"
              @click="dialog = false"
          >
            {{ $t('dialogs.files.createFolder.buttons.cancel') }}
          </v-btn>
          <v-btn
              color="primary"
              text
              :disabled="isLoading != null"
              :loading="isLoading === 'create'"
              @click="createFolder"
          >
            {{ $t('dialogs.files.createFolder.buttons.create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {FilesService} from "@/Services/FilesService";

export default {
  name: "CreateFolderDialog",

  props: ['show', 'entry'],

  data(){
    return {
      dialog: false,

      isLoading: null,

      valid: true,

      entryName: null,
      entryNameRules: [
        v => !!v || this.$t('dialogs.files.createFolder.fields.entryName.errors.required')
      ],
    }
  },
  watch: {
    show(){
      if(this.show){
        this.dialog = true;
        this.$emit('ResetShow');

        this.entryName = null;

        if(this.$refs.form)
          this.$refs.form.resetValidation();

        this.$nextTick(() => {
          if(this.$refs.inputField) {
            setTimeout(() => {
              this.$refs.inputField.focus()
            }, 0)
          }
        })
      }
    }
  },

  methods: {
    setLoading(isLoading){
      this.isLoading = isLoading;
    },
    createFolder(){
      if(!this.$refs.form.validate()) return;

      this.isLoading = 'create';

      FilesService.createDirectory(this.entry.path, this.entryName).then(() => {
        this.isLoading = null;
        this.dialog = false;

        this.$emit('FilesChanged')
      }).finally(() => {
        this.isLoading = null
      })
    }
  }
}
</script>

<style scoped>

</style>
