export class FilesUtils{
    static async fileEntriesToFiles(fileEntries){
        let result = []

        for(let i = 0; i < fileEntries.length; i++){
            result.push({
                file: await this.fileEntryToFile(fileEntries[i]),
                entry: fileEntries[i]
            })
        }

        return result
    }

    static async fileEntryToFile(fileEntry){
        return await new Promise((resolve, reject) => fileEntry.file(resolve, reject));
    }

    // Drop handler function to get all files
    static async getAllFileEntries(dataTransferItemList) {
        let fileEntries = [];
        // Use BFS to traverse entire directory/file structure
        let queue = [];
        // Unfortunately dataTransferItemList is not iterable i.e. no forEach
        for (let i = 0; i < dataTransferItemList.length; i++) {
            queue.push(dataTransferItemList[i].webkitGetAsEntry());
        }
        while (queue.length > 0) {
            let entry = queue.shift();
            if (entry.isFile) {
                fileEntries.push(entry);
            } else if (entry.isDirectory) {
                queue.push(...await FilesUtils.readAllDirectoryEntries(entry.createReader()));
            }
        }
        return fileEntries;
    }

    // Get all the entries (files or sub-directories) in a directory
    // by calling readEntries until it returns empty array
    static async readAllDirectoryEntries(directoryReader) {
        let entries = [];
        let readEntries = await FilesUtils.readEntriesPromise(directoryReader);
        while (readEntries.length > 0) {
            entries.push(...readEntries);
            readEntries = await FilesUtils.readEntriesPromise(directoryReader);
        }
        return entries;
    }

    // Wrap readEntries in a promise to make working with readEntries easier
    // readEntries will return only some of the entries in a directory
    // e.g. Chrome returns at most 100 entries at a time
    static async readEntriesPromise(directoryReader) {
        try {
            return await new Promise((resolve, reject) => {
                directoryReader.readEntries(resolve, reject);
            });
        } catch (err) {
            console.log(err);
        }
    }
}
