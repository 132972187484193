<template>
  <v-card class="fill-height pt-5">
    <v-container
        class="fill-height"
        fluid
    >
      <v-row
          align="center"
          justify="center"
      >
        <v-col
            cols="12"
            sm="8"
            md="4"
            xl="3"
        >
          <v-card class="elevation-12">
            <v-toolbar
                color="primary"
                dark
                flat
            >
              <v-toolbar-title>{{$t('pages.login.title')}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form
                  ref="form"
                  v-model="valid">
                <v-text-field
                    v-model="username"
                    :rules="usernameRules"
                    :label="$t('pages.login.fields.username.label')"
                    :hint="$t('pages.login.fields.username.hint')"
                    prepend-icon="mdi-account"
                    type="text"
                    :disabled="isLoading"
                    @keyup.enter="login"
                ></v-text-field>

                <v-text-field
                    v-model="password"
                    :rules="passwordRules"
                    :label="$t('pages.login.fields.password.label')"
                    :hint="$t('pages.login.fields.password.hint')"
                    prepend-icon="mdi-lock"
                    type="password"
                    :disabled="isLoading"
                    @keyup.enter="login"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  :loading="isLoading"
                  :disabled="!valid || isLoading"
                  @click="login"
              >{{$t('pages.login.buttons.login')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar
        v-model="snackbar.show"
        top
        color="error"
        timeout="3000"
    >
      {{$t(snackbar.text)}}
      <template v-slot:action="{ attrs }">
        <v-btn
            dark
            icon
            v-bind="attrs"
            color="white"
            @click="snackbar.show = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>
import AuthenticationService from "@/Services/AuthenticationService";

export default {
  name: "LoginView",

  data(){
    return {
      isLoading: false,
      snackbar: {
        text: null,
        show: false
      },
      valid: true,
      username: '',
      usernameRules: [
        v => !!v || this.$t('pages.login.fields.username.errors.required')
      ],
      password: '',
      passwordRules: [
        v => !!v || this.$t('pages.login.fields.password.errors.required')
      ]

    }
  },
  methods: {
    login(){
      this.isLoading = true;
      AuthenticationService.login(this.username, this.password).then(() => {
        this.goToLoggedIn();
      }).catch(err => {
        console.error(err);
      }).finally(() => {
        this.isLoading = false;
      })
    },
    goToLoggedIn(){
      let url = '/files'

      if(this.$route.query.returnUrl){
        url = this.$route.query.returnUrl;
      }

      this.$router.push(url)
    },
  },
  mounted() {
    if(AuthenticationService.hasSession()){
      this.goToLoggedIn();
    }
  }
}
</script>

<style scoped>

</style>
