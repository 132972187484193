<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        :persistent="isLoading != null"
        max-width="750"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('dialogs.files.upload.title') }}
        </v-card-title>
        <v-card-text>
          <v-container>

            <v-simple-table
              fixed-header
              height="500px"
            >
              <template v-slot:default>
                <thead>
                <tr>
                  <th class="text-left" style="width:50%">
                    {{ $t('dialogs.files.upload.headers.name') }}
                  </th>
                  <th class="text-left">
                    {{ $t('dialogs.files.upload.headers.size', {sum: formatSpace(myFiles.reduce((prev, cur) => prev + cur.file.size, 0))}) }}
                  </th>
                  <th class="text-right">
                    {{ $t('dialogs.files.upload.headers.progress') }}
                  </th>
                  <th class="text-right">
                    {{ $t('dialogs.files.upload.headers.actions') }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr
                    v-for="(item, index) in myFiles"
                    :key="index"
                    :class="{'text--disabled': isLoading != null}"
                >
                  <td>
                    <v-icon class="mr-2" :disabled="isLoading != null">mdi-file-outline</v-icon>
                    {{ item.fullPath }}
                    <v-tooltip bottom v-if="duplicates.includes(item.fullPath)">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            class="ml-2"
                            :disabled="isLoading != null"
                            color="error"
                            v-bind="attrs"
                            v-on="on"
                        >
                          mdi-alert-circle-outline
                        </v-icon>
                      </template>
                      <span>{{ $t('dialogs.files.upload.errors.noDuplicates') }}</span>
                    </v-tooltip>
                  </td>
                  <td>{{ formatSpace(item.file.size) }}</td>
                  <td class="align-end">
                    <v-row align="end" v-if="item.progress.state !== 'none'">
                      <v-col class="text-right">
                        <v-progress-circular v-if="item.progress.state === 'uploading'"
                                             color="primary"
                                             :value="item.progress.value"
                        />
                        <v-icon v-else-if="item.progress.state === 'finished'"
                                color="primary"
                        >
                          mdi-check
                        </v-icon>
                        <v-icon v-else-if="item.progress.state === 'waiting'"
                                disabled
                        >
                          mdi-clock-outline
                        </v-icon>
                        <v-icon v-else
                                color="error"
                        >
                          mdi-alert-circle-outline
                        </v-icon>
                      </v-col>
                    </v-row>
                  </td>
                  <td class="align-end">
                    <v-row align="end">
                      <v-col class="text-right">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                icon
                                v-on="on"
                                v-bind="attrs"
                                :disabled="isLoading != null"
                                @click.stop="deleteEntry(item)">
                              <v-icon
                                  small>
                                mdi-delete-outline
                              </v-icon>
                            </v-btn>
                          </template>

                          <span>{{ $t('dialogs.files.upload.buttons.delete') }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="secondary"
              text
              :disabled="isLoading != null"
              @click="dialog = false"
          >
            {{ $t('dialogs.files.upload.buttons.cancel') }}
          </v-btn>
          <v-btn
              color="primary"
              text
              :disabled="isLoading != null || valid !== true"
              :loading="isLoading === 'upload'"
              @click="upload"
          >
            {{ $t('dialogs.files.upload.buttons.upload') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import FormatUtils from "@/Utils/FormatUtils";
import {FilesService} from "@/Services/FilesService";

export default {
  name: "UploadDialog",

  props: ['show', 'files'],

  data(){
    return {
      dialog: false,
      valid: false,

      duplicates: [],
      myFiles: [],

      isLoading: null
    }
  },
  watch: {
    show(){
      if(this.show){
        this.dialog = true;
        this.$emit('ResetShow');
      }
    },
    files(){
      if(this.isLoading != null) return;

      if(this.files.length === 0){
        this.dialog = false;
        return;
      }

      const files = this.files;

      let found = [];
      let duplicates = [];

      files.forEach(f => {
        const fileName = f.fullPath

        if(found.includes(fileName)){
          if(!duplicates.includes(fileName)){
            duplicates.push(fileName)
          }
        }else{
          found.push(fileName)
        }
      })

      this.duplicates = duplicates;
      this.valid = duplicates.length === 0;

      this.myFiles = this.files;
    },
    dialog(){
      if(this.dialog === false){
        this.$emit('ClearEntries')
      }
    }
  },

  methods: {
    formatSpace: (space) => FormatUtils.formatSpace(space),
    setLoading(isLoading){
      this.isLoading = isLoading;
    },
    onDownloadProgress(i, e){
      this.myFiles[i].progress.cur = e.loaded
      this.myFiles[i].progress.value = (e.loaded / e.total) * 100
    },
    onDownloadFinish(i, err){
      if(!err){
        this.myFiles[i].progress.state = 'finished'
      }else{
        this.myFiles[i].progress.state = 'failed'
      }

      if(!this.myFiles.some(f => f.progress.state === 'uploading' || f.progress.state === 'waiting')){

        this.isLoading = null;

        this.$emit('ClearFinished', this.myFiles.filter(f => f.progress.state === 'finished'))
      }else{
        this.continueDownloads()
      }
    },
    continueDownloads(){
      const concurrent_limit = 5

      let uploading = this.myFiles.filter(f => f.progress.state === "uploading")
      let queue = this.myFiles.filter(f => f.progress.state === "waiting")

      // Limit already reached
      if(uploading.length >= concurrent_limit) return;
      // No elements in queue left
      if(queue.length === 0) return;

      // Depending on whether queue is larger or concurrent limit is larger, the smallest is chosen
      let remaining_limit = concurrent_limit - uploading.length;

      let limit = queue.length > remaining_limit ? remaining_limit : queue.length;

      for(let i = 0; i < limit; i++){

        const progress = queue[i].progress;

        console.log("Starting:", progress)

        this.myFiles[progress.index].progress.state = 'uploading';
        FilesService.uploadFile(progress.url, progress.file, progress.index, this.onDownloadProgress, this.onDownloadFinish)
      }
    },
    upload(){
      this.isLoading = 'upload'

      let baseLocation = this.$route.params.location;

      for(let i = 0; i < this.myFiles.length; i++){

        const file = this.myFiles[i];
        const url = `${baseLocation != null ? `${baseLocation}/` : ''}${file.fullPath}`

        this.myFiles[i].progress.state = 'waiting';
        this.myFiles[i].progress.max = file.file.size;
        this.myFiles[i].progress.cur = 0;
        this.myFiles[i].progress.value = 0;
        this.myFiles[i].progress.index = i;
        this.myFiles[i].progress.url = url;
        this.myFiles[i].progress.file = file.file;
      }

      this.continueDownloads()
    },
    deleteEntry(entry){
      this.$emit('DeleteEntry', entry);
    }
  }
}
</script>

<style scoped>

</style>
