<template>
  <div>
    <v-toolbar>
      <v-toolbar-title>{{$t('pages.shared.title')}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              :disabled="isLoading != null"
              :loading="isLoading === 'shared'"
              @click="loadShared">
            <v-icon>
              mdi-refresh
            </v-icon>
          </v-btn>
        </template>

        <span>{{ $t('pages.shared.buttons.refresh') }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left" style="width:20%">
            {{ $t('pages.shared.headers.id') }}
          </th>
          <th class="text-left" style="width:40%">
            {{ $t('pages.shared.headers.path') }}
          </th>
          <th class="text-left">
            {{ $t('pages.shared.headers.creator') }}
          </th>
          <th class="text-left">
            {{ $t('pages.shared.headers.creation') }}
          </th>
          <th class="text-left">
            {{ $t('pages.shared.headers.expiration') }}
          </th>
          <th class="text-left">
            {{ $t('pages.shared.headers.downloads') }}
          </th>
          <th class="text-right">
            {{ $t('pages.shared.headers.actions') }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="(item, index) in shared"
            :key="index"
            :class="{'text--disabled': isLoading != null}"
        >
          <td>{{ item.id }}</td>
          <td>{{ item.path }}</td>
          <td>{{item.creator.username}}</td>
          <td>{{dateFormatter.format(item.creation)}}</td>
          <td>{{item.expiration != null ? dateFormatter.format(item.expiration) : '-'}}</td>
          <td>{{item.downloads}}</td>
          <td class="align-end">
            <v-row align="end">
              <v-col class="text-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        :disabled="isLoading != null"
                        @click.stop="copyUrl(item)">
                      <v-icon
                          small>
                        mdi-clipboard-text-outline
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>{{ $t('pages.shared.buttons.copyUrl') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        :disabled="isLoading != null"
                        :loading="isLoading === 'delete' && selected === item"
                        @click.stop="deleteShared(item)">
                      <v-icon
                          small>
                        mdi-delete-outline
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>{{ $t('pages.shared.buttons.delete') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import FormatUtils from "@/Utils/FormatUtils";
import {ShareService} from "@/Services/ShareService";
import {DialogService} from "@/Services/DialogService";

export default {
  name: "SharedView",

  data(){
    return {
      isLoading: null,
      selected: null,

      dateFormatter: FormatUtils.dateTimeFormatter,
      shared: []
    }
  },

  methods: {
    loadShared(){
      this.isLoading = 'shared'

      ShareService.getShared().then(shared => {
        console.log('Loaded shared:',shared)
        this.shared = shared;
      }).finally(() => {
        this.isLoading = null
      })
    },
    deleteShared(shared){
      DialogService.addDialog({
        title: 'dialogs.shared.delete.title',
        text: 'dialogs.shared.delete.text',
        width: 400,
        buttons: [
          {
            text: 'dialogs.shared.delete.buttons.cancel',
            color: 'secondary'
          },
          {
            text: 'dialogs.shared.delete.buttons.delete',
            color: 'error'
          }
        ]
      }).then(res => {
        if(res !== 1) return;

        this.isLoading = 'delete';
        this.selected = shared;

        console.log("Delete shared:",shared);

        ShareService.deleteShared(shared.id).then(() => {
          this.loadShared();
        }).catch(() => {
          this.isLoading = null;
        })
      })
    },
    copyUrl(shared){
      const getUrl = window.location;
      const baseUrl = getUrl.protocol + "//" + getUrl.host;
      const url = `${baseUrl}/share/${shared.id}`
      navigator.clipboard.writeText(url)
    }
  },

  mounted() {
    this.loadShared();
  }
}
</script>

<style scoped>

</style>
