import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginView from "@/views/LoginView";
import FilesView from "@/views/FilesView";
import MainLayout from "@/components/MainLayout";
import UsersView from "@/views/UsersView";
import GroupsView from "@/views/GroupsView";
import ShareView from "@/views/ShareView";
import AuthenticationService from "@/Services/AuthenticationService";
import SharedView from "@/views/SharedView";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: AuthenticationService.getLoginUrl(),
    component: MainLayout,
    children: [
      {
        path: '/files/:location*',
        name: 'files',
        component: FilesView
      },
      {
        path: '/shared',
        name: 'shared',
        component: SharedView
      },
      {
        path: '/users',
        name: 'users',
        component: UsersView
      },
      {
        path: '/groups',
        name: 'groups',
        component: GroupsView
      }
    ]
  },
  {
    path: AuthenticationService.getLoginUrl(),
    name: 'login',
    component: LoginView
  },
  {
    path: '/share/:id',
    name: 'share',
    component: ShareView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
