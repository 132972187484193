<template>
  <div>
    <v-toolbar>
      <v-toolbar-title>{{$t('pages.users.title')}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              :disabled="isLoading != null"
              @click="createUser">
            <v-icon>
              mdi-account-plus-outline
            </v-icon>
          </v-btn>
        </template>

        <span>{{ $t('pages.users.buttons.createUser') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              :disabled="isLoading != null"
              :loading="isLoading === 'users'"
              @click="loadUsers">
            <v-icon>
              mdi-refresh
            </v-icon>
          </v-btn>
        </template>

        <span>{{ $t('pages.users.buttons.refresh') }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left" style="width:20%">
            {{ $t('pages.users.headers.id') }}
          </th>
          <th class="text-left" style="width:50%">
            {{ $t('pages.users.headers.username') }}
          </th>
          <th class="text-left">
            {{ $t('pages.users.headers.groups') }}
          </th>
          <th class="text-left">
            {{ $t('pages.users.headers.isAdmin') }}
          </th>
          <th class="text-right">
            {{ $t('pages.users.headers.actions') }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-ripple
            v-for="(item, index) in users"
            :key="index"
            @click.prevent="editUser(item)"
            style="cursor: pointer;user-select: none"
            :class="{'text--disabled': isLoading != null}"
        >
          <td>{{ item.id }}</td>
          <td>{{ item.username }}</td>
          <td>{{item.groups.join(', ')}}</td>
          <td><v-icon
              v-if="item.isAdmin === true"
              :class="{'text--disabled': isLoading != null}"
              small>mdi-check</v-icon></td>
          <td class="align-end">
            <v-row align="end">
              <v-col class="text-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        :disabled="isLoading != null"
                        @click.stop="editUser(item)">
                      <v-icon
                          small>
                        mdi-cog-outline
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>{{ $t('pages.users.buttons.edit') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        :disabled="isLoading != null"
                        :loading="isLoading === 'delete' && selected === item"
                        @click.stop="deleteUser(item)">
                      <v-icon
                          small>
                        mdi-delete-outline
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>{{ $t('pages.users.buttons.delete') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <user-dialog
        :show="dialog === 'user'"
        :user="selected"
        @ResetShow="dialog = null"
        @UsersChanged="loadUsers"/>
  </div>
</template>

<script>
import {UsersService} from "@/Services/UsersService";
import {DialogService} from "@/Services/DialogService";
import UserDialog from "@/components/dialogs/users/UserDialog";

export default {
  name: "UsersView",
  components: {UserDialog},
  data(){
    return {
      isLoading: null,
      selected: null,
      dialog: null,

      users: []
    }
  },

  methods: {
    loadUsers(){
      this.isLoading = 'users'

      UsersService.getUsers().then(users => {
        console.log('Loaded users:', users);
        this.users = users;
      }).finally(() => {
        this.isLoading = null;
      })
    },
    editUser(user){
      console.log('Edit user:',user);
      this.selected = user;
      this.dialog = 'user';
    },
    deleteUser(user){
      DialogService.addDialog({
        title: 'dialogs.users.delete.title',
        text: 'dialogs.users.delete.text',
        width: 400,
        buttons: [
          {
            text: 'dialogs.users.delete.buttons.cancel',
            color: 'secondary'
          },
          {
            text: 'dialogs.users.delete.buttons.delete',
            color: 'error'
          }
        ]
      }).then(res => {
        if(res !== 1) return;

        this.isLoading = 'delete';
        this.selected = user;

        console.log("Delete user:",user);

        UsersService.deleteUser(user.id).then(() => {
          this.loadUsers();
        }).catch(() => {
          this.isLoading = null;
        })
      })
    },
    createUser(){
      console.log('Create user')
      this.selected = null
      this.dialog = 'user'
    }
  },

  mounted() {
    this.loadUsers();
  }
}
</script>

<style scoped>

</style>
