import Vue from "vue";
import AuthenticationService from "@/Services/AuthenticationService";

export class ShareService{
    static async getDetails(shareId){
        const res = await Vue.axios.get(`share/${shareId}/details`, AuthenticationService.getAxiosConfig())

        return res.data;
    }

    static async getShared(){
        const res = await Vue.axios.get('shared', AuthenticationService.getAxiosConfig())

        return res.data.map(s => ({
            id: s.id,
            path: s.path,
            creator: s.creator,
            creation: new Date(s.creation),
            expiration: s.expiration != null ? new Date(s.expiration) : null,
            downloads: s.downloads
        }))
    }

    static async deleteShared(shareId){
        await Vue.axios.delete(`shared/${shareId}`, AuthenticationService.getAxiosConfig())
    }
}
