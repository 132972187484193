import store from '@/store'

export class DialogService{
    static addDialog(data){
        data.title = data.title || '...'
        data.text = data.text || '...'
        data.width = data.width || 400
        data.persistent = data.persistent || false
        data.buttons = data.buttons || []

        return new Promise(resolve => {
            data.resolve = resolve;
            store.commit('DialogModule/addDialog', data)
        })
    }
}
