<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        :persistent="isLoading != null"
        max-width="500"
    >
      <v-card>
        <v-card-title class="headline">
          <template v-if="group == null">{{$t('dialogs.groups.group.title.create')}}</template>
          <template v-if="group != null">{{$t('dialogs.groups.group.title.edit', {name: group.name})}}</template>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="valid">
              <v-row>
                <v-text-field
                    v-model="name"
                    :rules="nameRules"
                    :label="$t('dialogs.groups.group.fields.name.label')"
                    :hint="$t('dialogs.groups.group.fields.name.hint')"
                    :disabled="isLoading != null"
                    prepend-icon="mdi-form-textbox"
                    required
                ></v-text-field>
              </v-row>
              <v-row>
                <v-autocomplete
                    :label="$t('dialogs.groups.group.fields.users.label')"
                    :hint="$t('dialogs.groups.group.fields.users.hint')"
                    :disabled="isLoading != null"
                    v-model="users"
                    :items="availableUsers"
                    prepend-icon="mdi-account-multiple"
                    item-text="username"
                    item-value="id"
                    multiple
                    clearable>
                </v-autocomplete>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="secondary"
              text
              :disabled="isLoading != null"
              @click="dialog = false"
          >
            {{ $t('dialogs.groups.group.buttons.cancel') }}
          </v-btn>
          <v-btn
              color="primary"
              text
              :disabled="isLoading != null"
              :loading="isLoading === 'save'"
              @click="save"
          >
            {{ $t(group == null ? 'dialogs.groups.group.buttons.create' : 'dialogs.groups.group.buttons.save') }}
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {GroupsService} from "@/Services/GroupsService";

export default {
  name: "GroupDialog",

  props: ['show', 'group'],

  data(){
    return {
      dialog: false,

      isLoading: null,

      availableUsers: [],

      valid: true,
      name: null,
      nameRules: [
        v => !!v || this.$t('dialogs.groups.group.fields.name.errors.required')
      ],
      users: []
    }
  },
  watch: {
    show(){
      if(this.show){
        this.dialog = true;
        console.log(this.group)
        this.$emit('ResetShow');

        this.name = null;
        this.users = [];
        this.availableUsers = [];

        if(this.$refs.form)
          this.$refs.form.resetValidation();

        if(this.group != null){
          this.name = this.group.name
          this.users = this.group.users;
        }

        this.loadUsers();
      }
    }
  },

  methods: {
    setLoading(isLoading){
      this.isLoading = isLoading;
    },
    loadUsers(){
      this.isLoading = 'users'

      this.$axios.get('users').then(res => {
        console.log(res.data)

        this.availableUsers = res.data.map(u => ({
          id: u.id,
          username: u.username
        }))

        console.log(this.availableUsers)
      }).catch(err => {
        console.error(err)
        this.dialog = false;
      }).finally(() => {
        this.isLoading = null
      })
    },
    save(){
      if(!this.$refs.form.validate()) return;

      const data = {
        name: this.name,
        users: this.users
      }

      const promise = this.group != null ? GroupsService.editGroup(this.group.id, data) : GroupsService.createGroup(data);

      this.isLoading = 'save';

      promise.then(() => {
        this.isLoading = null;
        this.dialog = false;

        this.$emit('GroupsChanged')
      }).finally(() => {
        this.isLoading = null
      })
    }
  }
}
</script>

<style scoped>

</style>
