<template>
  <div @drop.prevent="onDrop">
    <upload-dialog
        :show="showDialog === true"
        :files="files"
        @ResetShow="showDialog = false"
        @DeleteEntry="deleteEntry"
        @ClearEntries="clearEntries"
        @ClearFinished="clearFinished"/>
    <div class="drop-indicator" :class="{active: this.showInfo}" @dragenter="onDragEnter" @dragover="onDragOver" @dragleave="onDragLeave" @drop="onDrop">
      <h1>
        {{ $t('components.dropHandler.text') }}
        <v-progress-circular
            :style="{'visibility': isLoading != null ? 'visible' : 'hidden'}"
            color="primary"
            indeterminate></v-progress-circular>
      </h1>

    </div>
  </div>
</template>

<script>
import UploadDialog from "@/components/dialogs/files/UploadDialog";
import {FilesUtils} from "@/Utils/FilesUtils";
export default {
  name: "DropHandler",

  components: {UploadDialog},
  data(){
    return {
      isLoading: null,
      showInfo: false,
      showDialog: false,

      files: []
    }
  },

  methods: {
    onDragEnter(e){
      e.preventDefault();
      e.stopPropagation()
      if(!this.isLoading)
        this.showInfo = e.dataTransfer.types.includes('Files');
    },
    onDragOver(e){
      e.preventDefault()
      e.stopPropagation()
      if(!this.isLoading)
        this.showInfo = e.dataTransfer.types.includes('Files');
    },
    onDragLeave(e){
      e.preventDefault();
      e.stopPropagation()
      if(!this.isLoading)
        this.showInfo = false;
    },
    onDrop(e){
      if(this.isLoading != null) return;

      this.isLoading = 'files'

      console.log("Dropped:", e);
      e.preventDefault();
      e.stopPropagation()

      FilesUtils.getAllFileEntries(e.dataTransfer.items).then(fileEntries => {
        FilesUtils.fileEntriesToFiles(fileEntries).then(files => {
          this.files.push(...files.map(f => ({
            fullPath: f.entry.fullPath.substring(1),
            file: f.file,
            progress: {
              state: 'none',
              max: 0,
              cur: 0,
              val: 0
            }
          })))
          this.showDialog = true
          this.showInfo = false;
          this.isLoading = null
        })
      })
    },
    deleteEntry(entry){
      this.files = this.files.filter(f => f !== entry)
    },
    clearEntries(){
      this.files = [];
    },
    clearFinished(finished){
      this.files = this.files.filter(f => !finished.includes(f))

      this.$emit('FilesChanged')
    }
  },

  mounted() {
    document.body.ondragenter = this.onDragEnter;
    /*document.body.ondragover = this.onDragOver;
    document.body.ondragleave = this.onDragLeave;*/
    document.body.ondrop = this.onDrop;
  },

  beforeDestroy() {
    document.body.ondragenter = null;
    document.body.ondragover = null;
    document.body.ondragend = null;
    document.body.ondrop = null;
  }
}
</script>

<style scoped>
.drop-indicator{
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5); /* Black background with opacity */
  z-index: 999; /* Specify a stack order in case you're using a different order for other elements */
}

.drop-indicator.active{
  display: block;
}

.drop-indicator>h1{
  margin: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  pointer-events: none;
}
</style>
