var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',[_c('v-toolbar-title',[_c('v-breadcrumbs',{attrs:{"items":_vm.breadcrumbs,"divider":"/"}})],1),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isLoading != null},on:{"click":function($event){$event.stopPropagation();return _vm.createDirectory.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-folder-plus-outline ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.files.buttons.createFolder')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isLoading != null || _vm.isAdmin !== true},on:{"click":function($event){$event.stopPropagation();return _vm.editSecurity(null)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-security ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.files.buttons.security')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isLoading != null,"loading":_vm.isLoading === 'entries'},on:{"click":function($event){$event.stopPropagation();return _vm.loadEntries.apply(null, arguments)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-refresh ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.files.buttons.reload')))])])],1),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",staticStyle:{"width":"50%"}},[_vm._v(" "+_vm._s(_vm.$t('pages.files.headers.name'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('pages.files.headers.size'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('pages.files.headers.lastModified'))+" ")]),_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t('pages.files.headers.actions'))+" ")])])]),_c('tbody',_vm._l((_vm.entries),function(item,index){return _c('tr',{directives:[{name:"ripple",rawName:"v-ripple"}],key:index,class:{'text--disabled': _vm.isLoading != null},staticStyle:{"cursor":"pointer","user-select":"none"},on:{"click":function($event){$event.preventDefault();return _vm.entryClicked(item)}}},[_c('td',[_c('v-icon',{staticClass:"mr-2",attrs:{"disabled":_vm.isLoading != null}},[_vm._v(_vm._s(item.isDirectory ? 'mdi-folder-outline' : 'mdi-file-outline'))]),_vm._v(" "+_vm._s(item.name)+" ")],1),_c('td',[_vm._v(_vm._s(_vm.formatSpace(item.size)))]),_c('td',[_vm._v(_vm._s(_vm.dateFormatter.format(item.lastModified)))]),_c('td',{staticClass:"align-end"},[_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isLoading != null || item.isDirectory},on:{"click":function($event){$event.stopPropagation();return _vm.shareEntry(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-share-variant-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.files.buttons.share')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isLoading != null || item.isDirectory,"loading":_vm.isLoading === 'download' && _vm.selected === item},on:{"click":function($event){$event.stopPropagation();return _vm.downloadEntry(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-download-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.files.buttons.download')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isLoading != null},on:{"click":function($event){$event.stopPropagation();return _vm.editName(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.files.buttons.rename')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-open-in-new ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.files.buttons.moveOrCopy')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isLoading != null || _vm.isAdmin !== true},on:{"click":function($event){$event.stopPropagation();return _vm.editSecurity(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-security ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.files.buttons.security')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isLoading != null,"loading":_vm.isLoading === 'delete' && _vm.selected === item},on:{"click":function($event){$event.stopPropagation();return _vm.deleteEntry(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.files.buttons.delete')))])])],1)],1)],1)])}),0)]},proxy:true}])}),_c('share-dialog',{attrs:{"show":_vm.dialog === 'share',"entry":_vm.selected},on:{"ResetShow":function($event){_vm.dialog = null}}}),_c('rename-dialog',{attrs:{"show":_vm.dialog === 'rename',"entry":_vm.selected},on:{"ResetShow":function($event){_vm.dialog = null},"FilesChanged":_vm.loadEntries}}),_c('security-dialog',{attrs:{"show":_vm.dialog === 'security',"entry":_vm.selected},on:{"ResetShow":function($event){_vm.dialog = null}}}),_c('create-folder-dialog',{attrs:{"show":_vm.dialog === 'create-folder',"entry":_vm.selected},on:{"ResetShow":function($event){_vm.dialog = null},"FilesChanged":_vm.loadEntries}}),_c('drop-handler',{on:{"FilesChanged":_vm.loadEntries}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }