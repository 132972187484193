// initial state
const state = () => ({
    username: '...',
    email: '...',
    isAdmin: false
})

// getters
const getters = {
    username: (state) => {
        return state.username;
    },
    email: (state) => {
        return state.email;
    },
    isAdmin: (state) => {
        return state.isAdmin === true;
    }
}

// actions
const actions = {

}

// mutations
const mutations = {
    setUserData(state, data){
        state.username = data.username;
        state.email = data.email;
        state.isAdmin = data.isAdmin;
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
