<template>
  <v-card class="fill-height pt-5">
    <v-container
        class="fill-height"
        fluid
    >
      <v-row
          align="center"
          justify="center"
      >
        <v-col
            cols="12"
            sm="8"
            md="4"
            xl="3"
        >
          <v-skeleton-loader
              v-if="isLoading === 'data'"
              class="mx-auto"
              type="card"
          ></v-skeleton-loader>
          <v-card class="elevation-12" v-if="isLoading !== 'data'">
            <v-toolbar
                color="primary"
                dark
                flat
            >
              <v-toolbar-title>{{filename}}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <p style="white-space: pre-line">{{$t('pages.share.text', {
                filename: filename,
                fileSize: formatSpace(fileSize),
                lastChange: dateFormatter.format(lastChange),
                sharedBy: sharedBy,
                expiration: expiration != null ? dateFormatter.format(expiration) : null
              })}}</p>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  :loading="isLoading === 'download'"
                  :disabled="isLoading != null"
                  @click="download"
              >{{$t('pages.share.buttons.download')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import {ShareService} from "@/Services/ShareService";
import FormatUtils from "@/Utils/FormatUtils";

export default {
  name: "ShareView.vue",

  data(){
    return {
      isLoading: 'data',

      dateFormatter: FormatUtils.dateTimeFormatter,

      filename: null,
      fileSize: null,
      lastChange: null,
      sharedBy: null,
      expiration: null
    }
  },

  watch: {
    '$route.params': {
      handler() {
        this.loadData();
      }
    }
  },

  methods: {
    formatSpace: (space) => FormatUtils.formatSpace(space),
    loadData(){
      this.isLoading = 'data'

      ShareService.getDetails(this.$route.params.id).then(details => {

        console.log('Details:', details)

        this.filename = details.filename;
        this.fileSize = details.fileSize;
        this.lastChange = new Date(details.lastChange);
        this.sharedBy = details.sharedBy;
        this.expiration = details.expiration != null ? new Date(details.expiration) : null

        this.isLoading = null;
      }).catch(err => {
        console.error(err);
      })
    },
    download(){
      this.isLoading = 'download'

      this.downloadItem(`share/${this.$route.params.id}`, this.filename).finally(() => {
        this.isLoading = null
      })
    },
    downloadEntry(entry){
      this.isLoading = 'download';
      this.selected = entry;

      console.log("Download entry:",entry);
      let location = this.$route.params.location ?? "";
      if(location.length > 0){
        location += `/${entry.name}`;
      }else {
        location += entry.name;
      }
      const url = `files/download/${location}`
      this.downloadItem(url, entry.name).finally(() => {
        this.isLoading = null;
      })
    },
    async downloadItem(url, label) {
      const response = await this.$axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data], { type: "application/octet-stream" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
    }
  },

  mounted() {
    this.loadData();
  }
}
</script>

<style scoped>

</style>
