import Vue from "vue";

export class GroupsService{
    static async getGroups(){
        const res = await Vue.axios.get('groups')

        return res.data;
    }

    static async deleteGroup(groupId){
        await Vue.axios.delete(`groups/${groupId}`)
    }

    static async createGroup(data){
        await Vue.axios.post('groups', data)
    }

    static async editGroup(groupId, data){
        await Vue.axios.post(`groups/${groupId}`, data)
    }
}
