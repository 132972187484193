import Vue from "vue";
import AuthenticationService from "@/Services/AuthenticationService";

export class FilesService{
    static async savePermissions(entryPath, groupEntries, userEntries){
        await Vue.axios.post(`files/security/${entryPath}`, {groupEntries, userEntries}, AuthenticationService.getAxiosConfig());
    }

    static async shareEntry(entryPath, expiration){
        return await Vue.axios.post(`files/share/${entryPath}`, {expiration}, AuthenticationService.getAxiosConfig())
    }

    static async createDirectory(parentEntryPath, folderName){
        await Vue.axios.post(`files/createFolder`, {parentEntryPath, folderName}, AuthenticationService.getAxiosConfig())
    }

    static async renameEntry(entryPath, newName){
        await Vue.axios.post(`files/rename/${entryPath}`, {newName}, AuthenticationService.getAxiosConfig())
    }

    static async uploadFile(entryPath, file, index, onProgress, onFinish){
        try {
            await Vue.axios.post(`files/upload/${entryPath}`, file, AuthenticationService.getAxiosConfig({
                headers: {
                    'content-type': file.type
                },
                onUploadProgress: (e) => onProgress(index, e)
            }))
            onFinish(index)
        }catch (e){
            console.error(e)
            onFinish(index, e)
        }
    }
}
