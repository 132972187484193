<template>
  <div>
    <v-toolbar>
      <v-toolbar-title><v-breadcrumbs :items="breadcrumbs" divider="/"></v-breadcrumbs></v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              :disabled="isLoading != null"
              @click.stop="createDirectory">
            <v-icon>
              mdi-folder-plus-outline
            </v-icon>
          </v-btn>
        </template>

        <span>{{ $t('pages.files.buttons.createFolder') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              :disabled="isLoading != null || isAdmin !== true"
              @click.stop="editSecurity(null)">
            <v-icon>
              mdi-security
            </v-icon>
          </v-btn>
        </template>

        <span>{{ $t('pages.files.buttons.security') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              :disabled="isLoading != null"
              :loading="isLoading === 'entries'"
              @click.stop="loadEntries">
            <v-icon>
              mdi-refresh
            </v-icon>
          </v-btn>
        </template>

        <span>{{ $t('pages.files.buttons.reload') }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left" style="width:50%">
            {{ $t('pages.files.headers.name') }}
          </th>
          <th class="text-left">
            {{ $t('pages.files.headers.size') }}
          </th>
          <th class="text-left">
            {{ $t('pages.files.headers.lastModified') }}
          </th>
          <th class="text-right">
            {{ $t('pages.files.headers.actions') }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-ripple
            v-for="(item, index) in entries"
            :key="index"
            @click.prevent="entryClicked(item)"
            style="cursor: pointer;user-select: none"
            :class="{'text--disabled': isLoading != null}"
        >
          <td>
            <v-icon class="mr-2" :disabled="isLoading != null">{{item.isDirectory ? 'mdi-folder-outline' : 'mdi-file-outline'}}</v-icon>
            {{ item.name }}
          </td>
          <td>{{ formatSpace(item.size) }}</td>
          <td>{{ dateFormatter.format(item.lastModified) }}</td>
          <td class="align-end">
            <v-row align="end">
              <v-col class="text-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        :disabled="isLoading != null || item.isDirectory"
                        @click.stop="shareEntry(item)">
                      <v-icon
                          small>
                        mdi-share-variant-outline
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>{{ $t('pages.files.buttons.share') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        :disabled="isLoading != null || item.isDirectory"
                        :loading="isLoading === 'download' && selected === item"
                        @click.stop="downloadEntry(item)">
                      <v-icon
                          small>
                        mdi-download-outline
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>{{ $t('pages.files.buttons.download') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        :disabled="isLoading != null"
                        @click.stop="editName(item)">
                      <v-icon
                          small>
                        mdi-pencil-outline
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>{{ $t('pages.files.buttons.rename') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        disabled>
                      <v-icon
                          small>
                        mdi-open-in-new
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>{{ $t('pages.files.buttons.moveOrCopy') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        :disabled="isLoading != null || isAdmin !== true"
                        @click.stop="editSecurity(item)">
                      <v-icon
                          small>
                        mdi-security
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>{{ $t('pages.files.buttons.security') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        :disabled="isLoading != null"
                        :loading="isLoading === 'delete' && selected === item"
                        @click.stop="deleteEntry(item)">
                      <v-icon
                          small>
                        mdi-delete-outline
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>{{ $t('pages.files.buttons.delete') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <share-dialog
        :show="dialog === 'share'"
        :entry="selected"
        @ResetShow="dialog = null"/>
    <rename-dialog
        :show="dialog === 'rename'"
        :entry="selected"
        @ResetShow="dialog = null"
        @FilesChanged="loadEntries"/>
    <security-dialog
        :show="dialog === 'security'"
        :entry="selected"
        @ResetShow="dialog = null"/>
    <create-folder-dialog
        :show="dialog === 'create-folder'"
        :entry="selected"
        @ResetShow="dialog = null"
        @FilesChanged="loadEntries"/>

    <drop-handler
        @FilesChanged="loadEntries"/>
  </div>
</template>

<script>
import FormatUtils from "@/Utils/FormatUtils";
import {mapGetters} from "vuex";
import {DialogService} from "@/Services/DialogService";
import ShareDialog from "@/components/dialogs/files/ShareDialog";
import RenameDialog from "@/components/dialogs/files/RenameDialog";
import SecurityDialog from "@/components/dialogs/files/SecurityDialog";
import CreateFolderDialog from "@/components/dialogs/files/CreateFolderDialog";
import DropHandler from "@/components/DropHandler";

export default {
  name: "FilesView",

  components: {
    DropHandler,
    CreateFolderDialog,
    ShareDialog,
    RenameDialog,
    SecurityDialog
  },

  data(){
    return {
      isLoading: null,
      selected: null,
      dialog: null,

      dateFormatter: FormatUtils.dateTimeFormatter,
      entries: [],
      breadcrumbs: [],
    }
  },

  computed: {
    ...mapGetters('UserModule', ['isAdmin'])
  },

  watch: {
    '$route'(){
      this.entries = [];
      this.loadEntries();
    }
  },

  methods: {
    formatSpace: (space) => FormatUtils.formatSpace(space),
    entryClicked(entry){
      if(this.isLoading != null) return;

      console.log("Entry clicked:",entry);
      if(entry.isDirectory !== true){
        this.downloadEntry(entry);
        return;
      }
      let location = this.$route.params.location ?? "";
      if(location.length > 0){
        location += `/${entry.name}`;
      }else {
        location += entry.name;
      }
      this.$router.push({params: {
          location: location
        }
      })
    },
    shareEntry(entry){
      let location = this.$route.params.location ?? "";
      if(location.length > 0){
        location += `/${entry.name}`;
      }else {
        location += entry.name;
      }
      entry.path = location
      this.selected = entry;
      this.dialog = 'share';
    },
    downloadEntry(entry){
      this.isLoading = 'download';
      this.selected = entry;

      console.log("Download entry:",entry);
      let location = this.$route.params.location ?? "";
      if(location.length > 0){
        location += `/${entry.name}`;
      }else {
        location += entry.name;
      }
      const url = `files/download/${location}`
      this.downloadItem(url, entry.name).finally(() => {
        this.isLoading = null;
      })
    },
    editName(entry){
      let location = this.$route.params.location ?? "";
      if(location.length > 0){
        location += `/${entry.name}`;
      }else {
        location += entry.name;
      }
      entry.path = location
      this.selected = entry;
      this.dialog = 'rename';
    },
    editSecurity(entry){
      if(entry == null){
        entry = {
          name: this.breadcrumbs[this.breadcrumbs.length - 1].text,
          path: this.$route.params.location ?? ""
        }
      }else{
        let location = this.$route.params.location ?? "";
        if(location.length > 0){
          location += `/${entry.name}`;
        }else {
          location += entry.name;
        }

        entry.path = location
      }

      this.selected = entry;
      this.dialog = 'security';
    },
    createDirectory(){
      const entry = {
        name: this.breadcrumbs[this.breadcrumbs.length - 1].text,
        path: this.$route.params.location ?? ""
      }
      this.selected = entry;
      this.dialog = 'create-folder';
    },
    deleteEntry(entry){
      DialogService.addDialog({
        title: 'dialogs.files.delete.title',
        text: 'dialogs.files.delete.text',
        width: 400,
        buttons: [
          {
            text: 'dialogs.files.delete.buttons.cancel',
            color: 'secondary'
          },
          {
            text: 'dialogs.files.delete.buttons.delete',
            color: 'error'
          }
        ]
      }).then(res => {
        if(res !== 1) return;

        this.isLoading = 'delete';
        this.selected = entry;

        console.log("Delete entry:",entry);
        let location = this.$route.params.location ?? "";
        if(location.length > 0){
          location += `/${entry.name}`;
        }else {
          location += entry.name;
        }
        const url = `files/delete/${location}`
        this.$axios.delete(url).then(() => {
          this.loadEntries();
        }).catch(() => {
          this.isLoading = null;
        })
      })
    },
    loadEntries(){
      this.isLoading = 'entries';

      const location = this.$route.params.location ?? "";
      const path = `${location}`;
      const paths = path.split('/');

      let breadcrumbs = [{
        text: '.',
        exact: true,
        to: this.$router.resolve({
          params: {
            location: null
          }
        }).href
      }];

      let curPath = '';

      for(const part of paths){
        if(part == '') continue;

        if(curPath.length > 0){
          curPath += `/${part}`;
        }else {
          curPath += part;
        }

        breadcrumbs.push({
          text: part,
          exact: true,
          to: this.$router.resolve({
            params: {
              location: curPath
            }
          }).href
        })
      }

      breadcrumbs[breadcrumbs.length - 1].disabled = true;

      this.breadcrumbs = breadcrumbs;

      this.$axios.get(`files/list/${location}`, {
        headers: {
          Authorization: 'Basic dGVzdDp0ZXN0'
        }
      }).then(res => {
        console.log(res);
        let entries = res.data.map(d => ({
          ...d,
          lastModified: new Date(d.lastModified)
        }))

        let directories = entries.filter(e => e.isDirectory === true)
        let files = entries.filter(e => e.isDirectory === false)

        directories.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))
        files.sort((a,b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0))


        this.entries = [
            ...directories,
            ...files
        ]
      }).finally(() => {
        this.isLoading = null
      })
    },

    async downloadItem(url, label) {
      const response = await this.$axios.get(url, { responseType: "blob" });
      const blob = new Blob([response.data], { type: "application/octet-stream" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
    }
  },
  mounted() {
    this.loadEntries();
  }
}
</script>

<style scoped>

</style>
