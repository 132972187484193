var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('pages.users.title')))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isLoading != null},on:{"click":_vm.createUser}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-account-plus-outline ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.users.buttons.createUser')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isLoading != null,"loading":_vm.isLoading === 'users'},on:{"click":_vm.loadUsers}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-refresh ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.users.buttons.refresh')))])])],1),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",staticStyle:{"width":"20%"}},[_vm._v(" "+_vm._s(_vm.$t('pages.users.headers.id'))+" ")]),_c('th',{staticClass:"text-left",staticStyle:{"width":"50%"}},[_vm._v(" "+_vm._s(_vm.$t('pages.users.headers.username'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('pages.users.headers.groups'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('pages.users.headers.isAdmin'))+" ")]),_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t('pages.users.headers.actions'))+" ")])])]),_c('tbody',_vm._l((_vm.users),function(item,index){return _c('tr',{directives:[{name:"ripple",rawName:"v-ripple"}],key:index,class:{'text--disabled': _vm.isLoading != null},staticStyle:{"cursor":"pointer","user-select":"none"},on:{"click":function($event){$event.preventDefault();return _vm.editUser(item)}}},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.username))]),_c('td',[_vm._v(_vm._s(item.groups.join(', ')))]),_c('td',[(item.isAdmin === true)?_c('v-icon',{class:{'text--disabled': _vm.isLoading != null},attrs:{"small":""}},[_vm._v("mdi-check")]):_vm._e()],1),_c('td',{staticClass:"align-end"},[_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isLoading != null},on:{"click":function($event){$event.stopPropagation();return _vm.editUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-cog-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.users.buttons.edit')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isLoading != null,"loading":_vm.isLoading === 'delete' && _vm.selected === item},on:{"click":function($event){$event.stopPropagation();return _vm.deleteUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.users.buttons.delete')))])])],1)],1)],1)])}),0)]},proxy:true}])}),_c('user-dialog',{attrs:{"show":_vm.dialog === 'user',"user":_vm.selected},on:{"ResetShow":function($event){_vm.dialog = null},"UsersChanged":_vm.loadUsers}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }