"use strict";

import Vue from 'vue';
import axios from "axios";
import AuthenticationService from "@/Services/AuthenticationService";
import router from "@/router";

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

let config = {
  baseURL: process.env.VUE_APP_API_URL || ''
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

export const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {

    config = AuthenticationService.getAxiosConfig(config);

    if(config.retry == null){
      config.retry = true;
    }

    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
    return response;
  },
  function(error) {

    if(!error.response)
    {
      return Promise.reject(error);
    }

    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401 || error.config.retry === false) {
      return Promise.reject(error);
    }

    let isTokenRequest = false;

    if(error.config.config != null){
      isTokenRequest = error.config.config.isTokenRequest === true;
    }else{
      isTokenRequest = error.config.isTokenRequest === true;
    }

    if(isTokenRequest === true){
      return Promise.reject(error);
    }

    // Try request again with new token
    return AuthenticationService.getNewToken(false)
        .then(() => {

          // New request with new token
          const config = error.config;

          config.retry = false;

          return axios.request(config)

        })
        .catch((error) => {
          AuthenticationService.logout();
          AuthenticationService.saveSession();

          if(router.currentRoute.path === AuthenticationService.getLoginUrl()){
            console.log("[DEBUG] Already at login url:", router.currentRoute)
          }else{
            console.log("[DEBUG] Not at login url:", router.currentRoute)

            router.push({path: AuthenticationService.getLoginUrl(), query: {returnUrl: router.currentRoute.path}});
          }

          return Promise.reject(error);
        });
  }
);

Plugin.install = function(Vue) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
