<template>
  <v-dialog
      v-model="dialog"
      :persistent="isLoading != null"
      max-width="500"
  >
    <v-card>
      <v-card-title class="headline">
        <template>{{$t('dialogs.changePassword.title')}}</template>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form
              ref="form"
              v-model="valid">
            <v-text-field
                v-model="oldPassword"
                :rules="oldPasswordRules"
                :label="$t('dialogs.changePassword.fields.oldPassword.label')"
                :hint="$t('dialogs.changePassword.fields.oldPassword.hint')"
                :error-messages="oldPasswordErrors"
                type="password"
                :disabled="isLoading != null"
                prepend-icon="mdi-form-textbox-lock"
                required
            ></v-text-field>
            <v-text-field
                v-model="password"
                :rules="passwordRules"
                :label="$t('dialogs.changePassword.fields.password.label')"
                :hint="$t('dialogs.changePassword.fields.password.hint')"
                type="password"
                :disabled="isLoading != null"
                prepend-icon="mdi-form-textbox-lock"
                required
            ></v-text-field>
            <v-text-field
                v-model="passwordAgain"
                :rules="passwordAgainRules"
                :label="$t('dialogs.changePassword.fields.passwordAgain.label')"
                :hint="$t('dialogs.changePassword.fields.passwordAgain.hint')"
                type="password"
                :disabled="isLoading != null"
                prepend-icon="mdi-form-textbox-lock"
                required
            ></v-text-field>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="secondary"
            text
            :disabled="isLoading != null"
            @click="dialog = false"
        >
          {{ $t('dialogs.changePassword.buttons.cancel') }}
        </v-btn>
        <v-btn
            color="primary"
            text
            :disabled="isLoading != null || valid !== true"
            :loading="isLoading === 'save'"
            @click="save"
        >
          {{ $t('dialogs.changePassword.buttons.save') }}
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import AuthenticationService from "@/Services/AuthenticationService";

export default {
  name: "ChangePasswordDialog",

  props: ['show'],

  data(){
    return {
      dialog: false,

      isLoading: null,

      valid: true,
      oldPassword: null,
      oldPasswordRules: [
        v => !!v || this.$t('dialogs.changePassword.fields.oldPassword.errors.required')
      ],
      oldPasswordErrors: null,
      password: null,
      passwordRules: [
        v => !!v || this.$t('dialogs.changePassword.fields.password.errors.required')
      ],
      passwordAgain: null,
      passwordAgainRules: [
        v => !!v || this.$t('dialogs.changePassword.fields.passwordAgain.errors.required'),
        v => v === this.password || this.$t('dialogs.changePassword.fields.passwordAgain.errors.mismatch')
      ],
      isAdmin: false
    }
  },
  watch: {
    show(){
      if(this.show){
        this.dialog = true;
        this.$emit('ResetShow');

        this.oldPassword = null;
        this.password = null;
        this.passwordAgain = null;

        if(this.$refs.form)
          this.$refs.form.resetValidation()
      }
    },
    oldPassword(){
      this.oldPasswordErrors = null;
    }
  },

  methods: {
    setLoading(isLoading){
      this.isLoading = isLoading;
    },
    save(){
      if(!this.$refs.form.validate()) return;

      console.log(this.$refs.form)

      const promise = AuthenticationService.changeMyPassword(this.oldPassword, this.password);

      this.isLoading = 'save';

      promise.then(() => {
        this.isLoading = null;
        this.dialog = false;

        this.$emit('Logout')
      }).catch(err => {
        if(err.response)
          if(err.response.status === 403)
            this.oldPasswordErrors = this.$t('dialogs.changePassword.fields.oldPassword.errors.wrong')

      }).finally(() => {
        this.isLoading = null
      })
    }
  }
}
</script>

<style scoped>

</style>
