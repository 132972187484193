<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        :persistent="isLoading != null"
        max-width="500"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('dialogs.files.share.title', {entryName: this.entry != null ? this.entry.name : ''}) }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <template v-if="state === 'creating'">
              <v-row>
                {{$t('dialogs.files.share.text.creating')}}
              </v-row>
              <v-row>
                <v-select
                    :label="$t('dialogs.files.share.fields.expiration.label')"
                    :hint="$t('dialogs.files.share.fields.expiration.hint')"
                    :disabled="isLoading != null"
                    v-model="expiration"
                    :items="availableExpirations"
                    prepend-icon="mdi-timer-sand"
                    item-text="text"
                    item-value="offset"
                    hide-details>
                </v-select>
              </v-row>
            </template>
            <template v-if="state === 'result'">
              <v-row>
                {{$t('dialogs.files.share.text.result')}}
              </v-row>
              <v-row>
                <v-text-field
                  label=""
                  :hint="resultHint"
                  readonly
                  append-icon="mdi-clipboard-text-outline"
                  @click:append="copyResult"
                  :value="resultUrl"
                  persistent-hint
                ></v-text-field>
              </v-row>
            </template>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <template v-if="state === 'creating'">
            <v-btn
                color="secondary"
                text
                :disabled="isLoading != null"
                @click="dialog = false"
            >
              {{ $t('dialogs.files.share.buttons.cancel') }}
            </v-btn>
            <v-btn
                v-if="state === 'creating'"
                color="primary"
                text
                :disabled="isLoading != null"
                :loading="isLoading === 'share'"
                @click="share"
            >
              {{ $t('dialogs.files.share.buttons.share') }}
            </v-btn>
          </template>
          <template v-if="state === 'result'">
            <v-btn
                color="primary"
                text
                :disabled="isLoading != null"
                @click="dialog = false"
            >
              {{ $t('dialogs.files.share.buttons.close') }}
            </v-btn>
          </template>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {FilesService} from "@/Services/FilesService";

export default {
  name: "ShareDialog",

  props: ['show', 'entry'],

  data(){
    return {
      dialog: false,

      isLoading: null,
      state: 'creating',

      availableExpirations: [
        {
          text: "1 Stunde",
          offset: 1 * 60 * 60000
        },
        {
          text: "3 Stunden",
          offset: 3 * 60 * 60000
        },
        {
          text: "6 Stunden",
          offset: 6 * 60 * 60000
        },
        {
          text: "12 Stunden",
          offset: 12 * 60 * 60000
        },
        {
          text: "1 Tag",
          offset: 1 * 24 * 60 * 60000
        },
        {
          text: "2 Tage",
          offset: 2 * 24 * 60 * 60000
        },
        {
          text: "5 Tage",
          offset: 5 * 24 * 60 * 60000
        },
        {
          text: "7 Tage",
          offset: 7 * 24 * 60 * 60000
        },
        {
          text: "immer",
          offset: null
        },
      ],

      expiration: 7 * 24 * 60 * 60000,

      resultUrl: null,
      resultHint: null
    }
  },
  watch: {
    show(){
      if(this.show){
        this.dialog = true;
        this.state = 'creating'
        this.resultUrl = null
        this.resultHint = null;
        this.$emit('ResetShow');
      }
    }
  },

  methods: {
    setLoading(isLoading){
      this.isLoading = isLoading;
    },
    share(){

      let expiration = null;

      if(this.expiration != null){
        expiration = new Date(new Date().getTime() + this.expiration)
      }

      this.isLoading = 'share';

      FilesService.shareEntry(this.entry.path, expiration).then(res => {
        console.log(res.data)
        const id = res.data;
        let getUrl = window.location;
        let baseUrl = getUrl.protocol + "//" + getUrl.host;
        const url = `${baseUrl}/share/${id}`

        this.resultUrl = url;

        this.state = 'result'
      }).catch((err) => {
        console.error(err)
      }).finally(() => {
        this.isLoading = null;
      })
    },
    copyResult(){
      this.resultHint = this.$t('dialogs.files.share.texts.copied')
      setTimeout(() => {
        this.resultHint = null
      }, 1250);
      navigator.clipboard.writeText(this.resultUrl)
    }
  }
}
</script>

<style scoped>

</style>
