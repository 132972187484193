var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('pages.shared.title')))]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isLoading != null,"loading":_vm.isLoading === 'shared'},on:{"click":_vm.loadShared}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-refresh ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.shared.buttons.refresh')))])])],1),_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left",staticStyle:{"width":"20%"}},[_vm._v(" "+_vm._s(_vm.$t('pages.shared.headers.id'))+" ")]),_c('th',{staticClass:"text-left",staticStyle:{"width":"40%"}},[_vm._v(" "+_vm._s(_vm.$t('pages.shared.headers.path'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('pages.shared.headers.creator'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('pages.shared.headers.creation'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('pages.shared.headers.expiration'))+" ")]),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('pages.shared.headers.downloads'))+" ")]),_c('th',{staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm.$t('pages.shared.headers.actions'))+" ")])])]),_c('tbody',_vm._l((_vm.shared),function(item,index){return _c('tr',{key:index,class:{'text--disabled': _vm.isLoading != null}},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.path))]),_c('td',[_vm._v(_vm._s(item.creator.username))]),_c('td',[_vm._v(_vm._s(_vm.dateFormatter.format(item.creation)))]),_c('td',[_vm._v(_vm._s(item.expiration != null ? _vm.dateFormatter.format(item.expiration) : '-'))]),_c('td',[_vm._v(_vm._s(item.downloads))]),_c('td',{staticClass:"align-end"},[_c('v-row',{attrs:{"align":"end"}},[_c('v-col',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isLoading != null},on:{"click":function($event){$event.stopPropagation();return _vm.copyUrl(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-clipboard-text-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.shared.buttons.copyUrl')))])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":_vm.isLoading != null,"loading":_vm.isLoading === 'delete' && _vm.selected === item},on:{"click":function($event){$event.stopPropagation();return _vm.deleteShared(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-delete-outline ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('pages.shared.buttons.delete')))])])],1)],1)],1)])}),0)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }