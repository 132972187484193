<template>
  <v-app id="inspire">
    <v-navigation-drawer
        v-model="drawer"
        ref="drawer"
        app
        clipped
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ username }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
            to="/files"
            link>
          <v-list-item-icon>
            <v-icon>mdi-file-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t('pages.files.title') }}
          </v-list-item-title>
        </v-list-item>
        <v-list-item
            to="/shared"
            link>
          <v-list-item-icon>
            <v-icon>mdi-share-variant-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t('pages.shared.title') }}
          </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <template v-if="isAdmin === true">
          <v-list-item
              to="/users"
              link>
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('pages.users.title') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
              to="/groups"
              link>
            <v-list-item-icon>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-icon>
            <v-list-item-title>
              {{ $t('pages.groups.title') }}
            </v-list-item-title>
          </v-list-item>
          <v-divider></v-divider>
        </template>
        <v-list-item
            @click="changePassword"
            link>
          <v-list-item-icon>
            <v-icon>mdi-lock-reset</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t('components.mainLayout.texts.changePassword') }}
          </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
            @click="logout"
            link>
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-title>
            {{ $t('components.mainLayout.texts.logout') }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar
        app
        clipped-left
        color="primary"
        dark
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title class="mr-12 align-center">
        <span class="title">{{ $t('title') }}</span>
      </v-toolbar-title>
    </v-app-bar>

    <v-main>
      <v-card style="height: 100%">
        <router-view></router-view>
      </v-card>
    </v-main>

    <change-password-dialog
        :show="dialog === 'change-password'"
        @Logout="logout"
        @ResetShow="dialog = null">
    </change-password-dialog>
    <dialog-manager></dialog-manager>
  </v-app>
</template>

<script>
import {mapGetters} from "vuex";
import DialogManager from "@/components/DialogManager";
import AuthenticationService from "@/Services/AuthenticationService";
import ChangePasswordDialog from "@/components/dialogs/ChangePasswordDialog";

export default {
  name: "MainLayout",
  components: {
    ChangePasswordDialog,
    DialogManager
  },
  data(){
    return {
      drawer: null,
      dialog: null
    }
  },

  computed: {
    ...mapGetters('UserModule', ['username','email','isAdmin'])
  },

  methods: {
    changePassword(){
      this.dialog = "change-password"
    },
    logout(){
      console.log("Logging out...");
      AuthenticationService.logout();
      this.$router.push(AuthenticationService.getLoginUrl())
    },
  }
}

</script>

<style scoped>

</style>
