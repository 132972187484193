import store from '@/store'
import {_axios as axios} from '@/plugins/axios'
import Vue from "vue";

const LoginUrl = "/login"

export default class AuthenticationService{

    static getLoginUrl(){
        return LoginUrl;
    }

    static session = null

    static init(){
        const sessionData = localStorage.getItem('session')

        if(sessionData != null){
            this.setSession(JSON.parse(sessionData))
        }
    }

    static setSession(sessionData){
        this.session = sessionData;

        store.commit('UserModule/setUserData', {
            email: '',
            username: sessionData?.username,
            isAdmin: sessionData?.isAdmin
        })
    }

    static login(username, password){
        return new Promise((resolve, reject) => {
            axios
                .post('authentication/token', { username, password }, {
                    retry: false
                })
                .then(response => {

                    this.setSession(response.data);
                    this.saveSession();

                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }

    static getNewToken(retry = true) {
        return new Promise((resolve, reject) => {
            axios
                .post('authentication/token', { refreshToken: this.getRefreshToken() }, {
                    retry,
                    isTokenRequest: true
                })
                .then(response => {

                    this.setSession(response.data);
                    this.saveSession();

                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                });
        })
    }


    static saveSession(){
        localStorage.setItem('session', JSON.stringify(this.session))
    }

    static getToken(){
        return this.session?.accessToken;
    }

    static getRefreshToken(){
        return this.session?.refreshToken;
    }

    static hasSession(){
        return this.session != null;
    }

    static getAxiosConfig(config){
        config ??= {}

        const token = this.getToken()

        if(token == null) return config;

        config.headers ??= {}
        config.headers['Authorization'] = `Bearer ${token}`

        return config;
    }

    static logout(){
        this.setSession(null)
    }

    static async changeMyPassword(oldPassword, newPassword){
        await Vue.axios.post(`authentication/@me/changePassword`, {
            oldPassword,
            newPassword
        }, AuthenticationService.getAxiosConfig())
    }
}
