<template>
  <div>
    <v-toolbar>
      <v-toolbar-title>{{$t('pages.groups.title')}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              :disabled="isLoading != null"
              @click="createGroup">
            <v-icon>
              mdi-account-multiple-plus-outline
            </v-icon>
          </v-btn>
        </template>

        <span>{{ $t('pages.groups.buttons.createGroup') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              icon
              v-on="on"
              v-bind="attrs"
              :disabled="isLoading != null"
              :loading="isLoading === 'groups'"
              @click="loadGroups">
            <v-icon>
              mdi-refresh
            </v-icon>
          </v-btn>
        </template>

        <span>{{ $t('pages.groups.buttons.refresh') }}</span>
      </v-tooltip>
    </v-toolbar>
    <v-simple-table>
      <template v-slot:default>
        <thead>
        <tr>
          <th class="text-left" style="width:20%">
            {{ $t('pages.groups.headers.id') }}
          </th>
          <th class="text-left" style="width:50%">
            {{ $t('pages.groups.headers.name') }}
          </th>
          <th class="text-left">
            {{ $t('pages.groups.headers.users') }}
          </th>
          <th class="text-right">
            {{ $t('pages.groups.headers.actions') }}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-ripple
            v-for="(item, index) in groups"
            :key="index"
            @click.prevent="editGroup(item)"
            style="cursor: pointer;user-select: none"
            :class="{'text--disabled': isLoading != null}"
        >
          <td>{{ item.id }}</td>
          <td>{{ item.name }}</td>
          <td>{{item.users.map(u => u.name).join(', ')}}</td>
          <td class="align-end">
            <v-row align="end">
              <v-col class="text-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        :disabled="isLoading != null"
                        @click.stop="editGroup(item)">
                      <v-icon
                          small>
                        mdi-cog-outline
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>{{ $t('pages.groups.buttons.edit') }}</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        :disabled="isLoading != null"
                        :loading="isLoading === 'delete' && selected === item"
                        @click.stop="deleteGroup(item)">
                      <v-icon
                          small>
                        mdi-delete-outline
                      </v-icon>
                    </v-btn>
                  </template>

                  <span>{{ $t('pages.groups.buttons.delete') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
    <group-dialog
        :show="dialog === 'group'"
        :group="selected"
        @ResetShow="dialog = null"
        @GroupsChanged="loadGroups"/>
  </div>
</template>

<script>
import {DialogService} from "@/Services/DialogService";
import {GroupsService} from "@/Services/GroupsService";
import GroupDialog from "@/components/dialogs/groups/GroupDialog";

export default {
  name: "GroupsView",
  components: {
    GroupDialog
  },
  data(){
    return {
      isLoading: null,
      selected: null,
      dialog: null,

      groups: []
    }
  },

  methods: {
    loadGroups(){
      this.isLoading = 'groups'

      GroupsService.getGroups().then(groups => {
        console.log('Loaded groups:', groups);
        this.groups = groups;
      }).finally(() => {
        this.isLoading = null;
      })
    },
    editGroup(group){
      console.log('Edit group:',group);
      this.selected = group;
      this.dialog = 'group';
    },
    deleteGroup(group){
      DialogService.addDialog({
        title: 'dialogs.groups.delete.title',
        text: 'dialogs.groups.delete.text',
        width: 400,
        buttons: [
          {
            text: 'dialogs.groups.delete.buttons.cancel',
            color: 'secondary'
          },
          {
            text: 'dialogs.groups.delete.buttons.delete',
            color: 'error'
          }
        ]
      }).then(res => {
        if(res !== 1) return;

        this.isLoading = 'delete';
        this.selected = group;

        console.log("Delete group:",group);

        GroupsService.deleteGroup(group.id).then(() => {
          this.loadGroups();
        }).catch(() => {
          this.isLoading = null;
        })
      })
    },
    createGroup(){
      console.log('Create group')
      this.selected = null
      this.dialog = 'group'
    }
  },

  mounted() {
    this.loadGroups();
  }
}
</script>

<style scoped>

</style>
