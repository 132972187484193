// initial state
const state = () => ({
    dialogs: []
})

// getters
const getters = {
    dialogs: (state) => {
        return state.dialogs;
    }
}

// actions
const actions = {

}

// mutations
const mutations = {
    addDialog(state, dialog){
        dialog.show = true;

        state.dialogs.push(dialog)
    },
    removeDialog(state, index){
        state.dialogs.splice(index, 1)
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
