<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        :persistent="isLoading != null"
        max-width="500"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t('dialogs.files.security.title', {entryName: this.entry != null ? this.entry.name : ''}) }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-autocomplete
                :label="$t('dialogs.files.security.fields.selected.label')"
                :hint="$t('dialogs.files.security.fields.selected.hint')"
                :disabled="isLoading != null"
                v-model="selected"
                :items="available"
                item-text="name"
                return-object
                clearable>
                <template v-slot:item="data">
                  <v-list-item-icon>
                    <v-icon>{{ data.item.isGroup === true ? 'mdi-account-group' : 'mdi-account' }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{data.item.name}}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-row>
            <template v-if="selected != null">
              <v-row>
                <v-checkbox
                    v-model="permissions.canSee"
                    :label=" $t('dialogs.files.security.fields.permissions.canSee')"
                    hide-details
                ></v-checkbox>
              </v-row>
              <v-row>
                <v-checkbox
                    v-model="permissions.canRead"
                    :label=" $t('dialogs.files.security.fields.permissions.canRead')"
                    hide-details
                ></v-checkbox>
              </v-row>
              <v-row>
                <v-checkbox
                    v-model="permissions.canWrite"
                    :label=" $t('dialogs.files.security.fields.permissions.canWrite')"
                    hide-details
                ></v-checkbox>
              </v-row>
              <v-row>
                <v-checkbox
                    v-model="permissions.canShare"
                    :label=" $t('dialogs.files.security.fields.permissions.canShare')"
                    hide-details
                ></v-checkbox>
              </v-row>
            </template>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="secondary"
              text
              :disabled="isLoading != null"
              @click="dialog = false"
          >
            {{ $t('dialogs.files.security.buttons.cancel') }}
          </v-btn>
          <v-btn
              color="primary"
              text
              :disabled="isLoading != null"
              :loading="isLoading === 'save'"
              @click="save"
          >
            {{ $t('dialogs.files.security.buttons.save') }}
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {FilesService} from "@/Services/FilesService";

export default {
  name: "SecurityDialog",

  props: ['show', 'entry'],

  data(){
    return {
      dialog: false,

      isLoading: null,

      userEntries: [],
      groupEntries: [],

      available: [],
      selected: null,
      selectedEntry: null,

      permissions: {
        canSee: false,
        canRead: false,
        canWrite: false,
        canShare: false
      }
    }
  },
  watch: {
    show(){
      if(this.show){
        this.dialog = true;
        console.log(this.entry)
        this.$emit('ResetShow');

        if(this.entry != null)
          this.loadSecurity();
      }
    },
    selected(){
      // Nobody was selected => default values
      if(this.selected == null){
        this.permissions.canSee = false;
        this.permissions.canRead = false;
        this.permissions.canWrite = false;
        this.permissions.canShare = false;

        this.selectedEntry = null;

        return;
      }

      // Differentiate between group and user
      if(this.selected.isGroup === true){
        this.selectedEntry = this.groupEntries.find(g => g.id === this.selected.id);

        if(this.selectedEntry == null){
          this.selectedEntry = {
            id: this.selected.id,
            name: this.selected.name,
            permissions: 0
          }
          this.groupEntries.push(this.selectedEntry)
        }else{
          this.permissions.canSee   = (this.selectedEntry.permissions  & 0b0001) !== 0;
          this.permissions.canRead  = (this.selectedEntry.permissions  & 0b0010) !== 0;
          this.permissions.canWrite = (this.selectedEntry.permissions  & 0b0100) !== 0;
          this.permissions.canShare = (this.selectedEntry.permissions  & 0b1000) !== 0;
        }

      }else{
        this.selectedEntry = this.userEntries.find(u => u.id === this.selected.id);

        if(this.selectedEntry == null){
          this.selectedEntry = {
            id: this.selected.id,
            username: this.selected.name,
            permissions: 0
          }
          this.userEntries.push(this.selectedEntry)
        }else{
          this.permissions.canSee   = (this.selectedEntry.permissions  & 0b0001) !== 0;
          this.permissions.canRead  = (this.selectedEntry.permissions  & 0b0010) !== 0;
          this.permissions.canWrite = (this.selectedEntry.permissions  & 0b0100) !== 0;
          this.permissions.canShare = (this.selectedEntry.permissions  & 0b1000) !== 0;
        }
      }
    },
    permissions: {
      handler(){
        this.selectedEntry.permissions = 0;
        if(this.permissions.canSee === true)
          this.selectedEntry.permissions += 0b0001

        if(this.permissions.canRead === true)
          this.selectedEntry.permissions += 0b0010

        if(this.permissions.canWrite === true)
          this.selectedEntry.permissions += 0b0100

        if(this.permissions.canShare === true)
          this.selectedEntry.permissions += 0b1000
      },
      deep: true
    }
  },

  methods: {
    setLoading(isLoading){
      this.isLoading = isLoading;
    },
    loadSecurity(){
      this.isLoading = 'data'

      this.$axios.get(`files/security/${this.entry.path}`).then(res => {
        console.log(res.data)

        this.available = [
            ...res.data.availableUsers.map(u => ({
              isGroup: false,
              id: u.id,
              name: u.username
            })),
          ...res.data.availableGroups.map(g => ({
            isGroup: true,
            id: g.id,
            name: g.name
          })),
        ]

        this.userEntries = res.data.userEntries
        this.groupEntries = res.data.groupEntries
      }).catch(err => {
        console.error(err)
        this.dialog = false;
      }).finally(() => {
        this.isLoading = null
      })
    },
    save(){
      this.isLoading = 'save'
      FilesService.savePermissions(this.entry.path, this.groupEntries, this.userEntries).then(() => {
        this.dialog = false
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        this.isLoading = null
      })
    }
  }
}
</script>

<style scoped>

</style>
