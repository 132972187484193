import Vue from "vue";
import AuthenticationService from "@/Services/AuthenticationService";

export class UsersService{
    static async getUsers(){
        const res = await Vue.axios.get('users', AuthenticationService.getAxiosConfig())

        return res.data;
    }

    static async deleteUser(userId){
        await Vue.axios.delete(`users/${userId}`, AuthenticationService.getAxiosConfig())
    }

    static async createUser(data){
        await Vue.axios.post('users', data, AuthenticationService.getAxiosConfig())
    }

    static async editUser(userId, data){
        await Vue.axios.post(`users/${userId}`, data, AuthenticationService.getAxiosConfig())
    }
}
