<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        :persistent="isLoading != null"
        max-width="500"
    >
      <v-card>
        <v-card-title class="headline">
          <template v-if="user == null">{{$t('dialogs.users.user.title.create')}}</template>
          <template v-if="user != null">{{$t('dialogs.users.user.title.edit', {username: user.username})}}</template>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="valid">
              <v-text-field
                  v-model="username"
                  :rules="usernameRules"
                  :label="$t('dialogs.users.user.fields.username.label')"
                  :hint="$t('dialogs.users.user.fields.username.hint')"
                  :disabled="isLoading != null"
                  prepend-icon="mdi-form-textbox"
                  required
              ></v-text-field>
              <v-checkbox
                  v-if="user != null"
                  v-model="overwritePassword"
                  :label="$t('dialogs.users.user.fields.overwritePassword.label')"
                  :disabled="isLoading != null"
                  hide-details
              ></v-checkbox>
              <v-text-field
                  v-model="password"
                  :rules="passwordRules"
                  :label="$t('dialogs.users.user.fields.password.label')"
                  :hint="$t('dialogs.users.user.fields.password.hint')"
                  type="password"
                  :disabled="isLoading != null || (user != null && overwritePassword != true)"
                  prepend-icon="mdi-form-textbox-lock"
              ></v-text-field>
              <v-checkbox
                  v-model="isAdmin"
                  :label="$t('dialogs.users.user.fields.isAdmin.label')"
                  :disabled="isLoading != null"
                  hide-details
              ></v-checkbox>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="secondary"
              text
              :disabled="isLoading != null"
              @click="dialog = false"
          >
            {{ $t('dialogs.users.user.buttons.cancel') }}
          </v-btn>
          <v-btn
              color="primary"
              text
              :disabled="isLoading != null"
              :loading="isLoading === 'save'"
              @click="save"
          >
            {{ $t(user == null ? 'dialogs.users.user.buttons.create' : 'dialogs.users.user.buttons.save') }}
          </v-btn>

        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import {UsersService} from "@/Services/UsersService";

export default {
  name: "UserDialog",

  props: ['show', 'user'],

  data(){
    return {
      dialog: false,

      isLoading: null,

      valid: true,
      username: null,
      usernameRules: [
        v => !!v || this.$t('dialogs.users.user.fields.username.errors.required')
      ],
      overwritePassword: false,
      password: null,
      passwordRules: [
        v => (!!v || (this.overwritePassword == false && this.user != null)) || this.$t('dialogs.users.user.fields.password.errors.required')
      ],
      isAdmin: false
    }
  },
  watch: {
    show(){
      if(this.show){
        this.dialog = true;
        console.log(this.user)
        this.$emit('ResetShow');

        this.overwritePassword = false

        this.username = null;
        this.password = null;
        this.isAdmin = false;

        if(this.$refs.form)
          this.$refs.form.resetValidation()

        if(this.user != null){
          this.username = this.user.username;
          this.isAdmin = this.user.isAdmin;
        }
      }
    }
  },

  methods: {
    setLoading(isLoading){
      this.isLoading = isLoading;
    },
    save(){
      if(!this.$refs.form.validate()) return;

      const data = {
        username: this.username,
        password: this.password,
        isAdmin: this.isAdmin === true
      }

      const promise = this.user != null ? UsersService.editUser(this.user.id, data) : UsersService.createUser(data);

      this.isLoading = 'save';

      promise.then(() => {
        this.isLoading = null;
        this.dialog = false;

        this.$emit('UsersChanged')
      }).finally(() => {
        this.isLoading = null
      })
    }
  }
}
</script>

<style scoped>

</style>
